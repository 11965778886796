<template>
  <transition v-if="modal.open" name="modal">
    <div class="vue-modal user-preview-modal modal-mask">
      <div class="vue-modal--wrapper">
        <div v-click-away="closeModal" class="vue-modal--container modal-dialog">
          <div class="auth">
            <div class="preview-body">
              <div class="two-column">
                <div class="first">
                  <div class="main-image-wrap zoom-image-preview">
                    <img
                      v-if="photo && photo.watermark"
                      class="user-avatar img-responsive"
                      :src="photo.watermark"
                      :alt="$t('guest.modals.randomAva')"
                    />
                  </div>
                </div>
                <div class="second">
                  <div class="title-wrap">
                    <p class="user-preview-modal__title">
                      {{ $t('guest.modals.registerTitle') }}
                    </p>
                    <button type="button" class="close" @click="closeModal">
                      <span class="rbi-close" />
                    </button>
                  </div>
                  <form class="user-preview-modal__form" @submit.prevent="submitRegistration">
                    <div class="form-group" :class="{ 'has-error': errors.first('modRegSex') }">
                      <select
                        v-model="sex"
                        :data-vv-as="$t('shared.placeholders.sex')"
                        name="modRegSex"
                        class="form-control"
                      >
                        <option :value="1">I am man seeking for woman</option>
                        <option :value="2">I am woman seeking for man</option>
                      </select>
                    </div>
                    <div class="form-group" :class="{ 'has-error': err.first('modRegName') }">
                      <input
                        v-model="name"
                        type="text"
                        class="form-control"
                        name="modRegName"
                        :placeholder="$t('shared.placeholders.name')"
                      />
                      <div v-show="err.has('modRegName')" class="error">
                        <i class="fa fa-warning" />
                        <span>{{ err.first('modRegName') }}</span>
                      </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': errors.first('modRegDob') }">
                      <div style="display: flex; justify-content: space-between">
                        <select
                          v-model="month"
                          v-validate="'required'"
                          :data-vv-as="$t('shared.placeholders.month')"
                          name="modRegMonth"
                          class="form-control"
                          style="width: 40%"
                        >
                          <option disabled value>Month</option>
                          <option v-for="item in months" :key="item" :value="item">
                            {{ item }}
                          </option>
                        </select>
                        <select
                          v-model="day"
                          v-validate="'required'"
                          :data-vv-as="$t('shared.placeholders.day')"
                          name="modRegDay"
                          style="width: 25%"
                          class="form-control"
                        >
                          <option disabled value>Day</option>
                          <option v-for="item in days" :key="item" :value="item">
                            {{ item }}
                          </option>
                          <option v-if="month !== 'February'" :value="29">29</option>
                          <option v-if="month !== 'February'" :value="30">30</option>
                          <option v-if="isFullMonth(month)" :value="31">31</option>
                        </select>
                        <select
                          v-model="year"
                          v-validate="'required'"
                          :data-vv-as="$t('shared.placeholders.year')"
                          name="modRegYear"
                          style="width: 25%"
                          class="form-control"
                        >
                          <option disabled value>Year</option>
                          <option v-for="item in years" :key="item" :value="item">
                            {{ item }}
                          </option>
                        </select>
                      </div>
                      <transition name="slide-fade">
                        <div
                          v-show="
                            errors.has('modRegMonth') ||
                            errors.has('modRegDay') ||
                            errors.has('modRegYear') ||
                            isInvalidDob(day, month, year)
                          "
                          class="error"
                        >
                          <span class="is-danger">The date of birth field is required.</span>
                        </div>
                      </transition>
                    </div>
                    <div class="form-group" :class="{ 'has-error': err.first('modRegEmail') }">
                      <input
                        v-model="email"
                        type="email"
                        class="form-control"
                        name="modRegEmail"
                        :placeholder="$t('shared.placeholders.email')"
                      />
                      <div v-show="err.has('modRegEmail')" class="error">
                        <i class="fa fa-warning" />
                        <span>{{ err.first('modRegEmail') }}</span>
                      </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': err.first('modRegPassword') }">
                      <input
                        v-model="password"
                        type="password"
                        class="form-control"
                        name="modRegPassword"
                        :placeholder="$t('shared.placeholders.password')"
                      />
                      <div v-show="err.has('modRegPassword')" class="error">
                        <i class="fa fa-warning" />
                        <span>{{ err.first('modRegPassword') }}</span>
                      </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': err.first('modRegTerms') }">
                      <div class="custom-checkbox">
                        <input
                          id="preview-checkbox"
                          v-model="rules"
                          name="prRegTerms"
                          class="inp-cbx"
                          type="checkbox"
                          style="display: none"
                        />
                        <label class="cbx" for="preview-checkbox">
                          <span>
                            <svg width="12px" height="10px" viewBox="0 0 12 10">
                              <polyline points="1.5 6 4.5 9 10.5 1" />
                            </svg>
                          </span>
                          <span>
                            {{ $t('guest.indexHeader.agreement.text') }}
                            <router-link :to="{ name: 'terms' }">{{
                              $t('guest.indexHeader.agreement.link')
                            }}</router-link>
                          </span>
                        </label>
                        <transition name="slide-fade">
                          <div v-show="err.has('modRegTerms')" class="error">
                            <i class="fa fa-warning is-danger" />
                            <span class="help is-danger">{{ err.first('modRegTerms') }}</span>
                          </div>
                        </transition>
                      </div>
                    </div>
                    <div class="form-group d-flex justify-content-between flex-wrap">
                      <rb-button type="submit" class="btn-outline register-btn" :class="{ bdn: enteringReg }">
                        <span v-if="enteringReg" :class="{ 'wait-spin': enteringReg }" />
                        <span :class="{ wait: enteringReg }">{{
                          $t('guest.indexHeader.btns.register')
                        }}</span>
                      </rb-button>
                      <span class="another-variants">OR</span>
<!--                      <rb-button class="btn-black" type="button" @click.native="fbLogin">-->
<!--                        <span class="rbi-facebook" />-->
<!--                        {{ $t('guest.indexHeader.btns.facebookLogin') }}-->
<!--                      </rb-button>-->
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Validator } from 'vee-validate';
import { REGISTRATION, LOGIN } from '../../../mixins/formsActions';
import { WATERMARK } from '../../../mixins/utils';
import RbButton from '../../Button';

export default {
  regValidator: null,
  components: {
    RbButton,
  },
  mixins: [REGISTRATION, WATERMARK, LOGIN],
  data() {
    return {
      enteringReg: false,
      readonly: true,
      err: [],
      photo: {},
      name: '',
      email: '',
      password: '',
      rules: '',
      sex: 1,
      day: '',
      month: '',
      year: '',
      days: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28,
      ],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    };
  },
  computed: {
    modal() {
      return this.$store.getters.registerModal;
    },
    modalIsOpened() {
      return this.modal.open;
    },
  },
  watch: {
    modalIsOpened(opened) {
      if (opened) {
        this.$http.post('v1/user-guest/random-avatar-woman', {}).then(
          (response) => {
            response.json().then(
              (response) => {
                this.setWatermark(response.imgSrc);
              },
              (error) => {
                console.log(error);
              }
            );
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
    /**
     * Валидация поля "имя"
     *
     * @param val {string}
     */
    name(val) {
      this.regValidator.validate('modRegName', val).catch(() => {});
    },
    /**
     * Валидация поля "день"
     *
     * @param val {string}
     */
    day(val) {
      this.regValidator.validate('modRegDay', val).catch(() => {});
    },
    /**
     * Валидация поля "месяц"
     *
     * @param val {string}
     */
    month(val) {
      this.regValidator.validate('modRegMonth', val).catch(() => {});
    },
    /**
     * Валидация поля "год"
     *
     * @param val {string}
     */
    year(val) {
      this.regValidator.validate('modRegYear', val).catch(() => {});
    },
    /**
     * Валидация поля "email"
     *
     * @param val {string}
     */
    email(val) {
      this.regValidator.validate('modRegEmail', val).catch(() => {});
    },
    /**
     * Валидация поля "пароль"
     *
     * @param val {string}
     */
    password(val) {
      this.regValidator.validate('modRegPassword', val).catch(() => {});
    },
    /**
     * Валидация поля "пользовательское соглашение"
     *
     * @param val {string}
     */
    rules(val) {
      const tmp = val ? 1 : '';
      this.regValidator.validate('modRegTerms', tmp).catch(() => {});
    },
  },
  created() {
    /**
     * Инициализация плагина с валидацией для формы регистрации
     *
     * @type {Validator}
     */
    this.regValidator = new Validator();
    this.regValidator.attach('modRegName', 'required|alpha_spaces', {
      prettyName: this.$t('shared.placeholders.name'),
    });
    this.regValidator.attach('modRegDay', 'required', {
      prettyName: this.$t('shared.placeholders.day'),
    });
    this.regValidator.attach('modRegMonth', 'required', {
      prettyName: this.$t('shared.placeholders.month'),
    });
    this.regValidator.attach('modRegYear', 'required', {
      prettyName: this.$t('shared.placeholders.year'),
    });
    this.regValidator.attach('modRegEmail', 'required|email', {
      prettyName: this.$t('shared.placeholders.email'),
    });
    this.regValidator.attach('modRegPassword', 'required|alpha_num|min:8', {
      prettyName: this.$t('shared.placeholders.password'),
    });
    this.regValidator.attach('modRegTerms', 'required', {
      prettyName: this.$t('shared.placeholders.license'),
    });
    this.$set(this, 'err', this.regValidator.errorBag); // update the data.
  },
  mounted() {
    /**
     * Загрузить аватар случайной девушки
     */
  },
  methods: {
    monthToNum(month) {
      switch (month) {
        case 'January':
          return 1;
        case 'February':
          return 2;
        case 'March':
          return 3;
        case 'April':
          return 4;
        case 'May':
          return 5;
        case 'June':
          return 6;
        case 'July':
          return 7;
        case 'August':
          return 8;
        case 'September':
          return 9;
        case 'October':
          return 10;
        case 'November':
          return 11;
        case 'December':
          return 12;
      }
    },
    isInvalidDob(day, month, year) {
      if (day && month && year) {
        const d = new Date(`${month} ${day}, ${year}`);
        const now = new Date();
        // eslint-disable-next-line no-inner-declarations
        function diff_years(dt2, dt1) {
          let diff = (dt2.getTime() - dt1.getTime()) / 1000;
          diff /= 60 * 60 * 24;
          return Math.abs(Math.ceil(diff / 365.26));
        }
        return !(diff_years(d, now) >= 18);
      }
      return false;
    },
    isFullMonth(month) {
      if (
        month === 'January' ||
        month === 'March' ||
        month === 'May' ||
        month === 'July' ||
        month === 'August' ||
        month === 'October' ||
        month === 'December' ||
        month === ''
      )
        return true;
      return false;
    },
    unReadonly() {
      this.readonly = false;
    },
    /**
     * Закрыть модалку
     */
    closeModal() {
      this.err.clear();
      this.$store.commit('updateRegisterModal', {
        open: false,
      });
    },
    /**
     * Обработчик логина через фейсбук. Закрывает модалку
     */
    fbLogin() {
      this.closeModal();
      /*this.FBLoginStatus()
        .then(() => {
          this.closeModal();
        })
        .catch(() => {
          this.closeModal();
        });*/
    },
    /**
     * Отправить запрос на регистрацию пользователя с предварительной валидацией
     */
    submitRegistration() {
      const self = this;
      this.enteringReg = true;
      const rules = this.rules ? 1 : '';
      this.regValidator
        .validateAll({
          modRegName: this.name,
          modRegEmail: this.email,
          modRegPassword: this.password,
          modRegDay: this.day,
          modRegMonth: this.month,
          modRegYear: this.year,
          modRegTerms: rules,
        })
        .then((success) => {
          if (success) {
            this.mixinRegistration({
              sex: this.sex,
              dob: `${this.day < 10 ? `0${this.day}` : this.day}.${
                this.monthToNum(this.month) < 10
                  ? `0${this.monthToNum(this.month)}`
                  : this.monthToNum(this.month)
              }.${this.year}`,
              name: this.name,
              email: this.email,
              password: this.password,
            })
              .then(() => this.closeModal())
              .catch((err) => {
                console.log(err);
                self.$store.commit('addAlert', {
                  type: 'error',
                  text: err || 'Some error happened.',
                });
                this.enteringReg = false;
              });
          }
        })
        .catch((err) => {
          console.log(err);
          /* self.$store.commit('addAlert', {
            type: 'error',
            text: err ? err : 'Some error happened.',
          }) */
          this.enteringReg = false;
        });
    },
    /**
     * Установить ватермарку
     * *
     *
     * @param v {string} ссылка с картинкой
     */
    setWatermark(v) {
      this.getWatermark({
        targetObj: this.photo,
        parentImg: v,
        wmImg: this.config.watermarkSmall,
        modifierX: 10,
        modifierY: 10,
        variable: 'watermark',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/vars';
.user-preview-modal {
  &__title {
    &::first-letter {
      color: $red;
    }
  }
}
.wait-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0);
  width: 20px;
  height: 20px;
  border: 2px solid $black;
  border-left-color: transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  animation: spin 1s infinite linear;
}
.register-btn {
  width: 100%;
}
@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.user-preview-modal .first {
  background: #f6f6f6;
  padding: 5px;
  overflow: hidden;
}

.zoom-image-preview {
  transform: scale(1.35);
}

.another-variants {
  font-size: $basic-font-size;
  font-weight: 300;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  text-align: center;
  padding: 18px 0 15px;
  margin: 0;
  display: none;
}

@media screen and (max-width: 890px) {
  .zoom-image-preview {
    transform: scale(1.5);
  }

  .user-preview-modal .second {
    padding: 15px 25px;
  }

  .user-preview-modal .auth .preview-body .btn {
    padding-left: 15px;
    padding-right: 15px;
  }

  .user-preview-modal .auth .preview-body .d-flex {
    margin-top: 15px;
  }

  .user-preview-modal .form-group {
    margin-bottom: 5px;
  }
  .user-preview-modal .custom-checkbox {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .user-preview-modal {
    .auth .preview-body .d-flex {
      margin-top: 30px;
    }

    .form-group {
      margin-bottom: 10px;
    }
    .custom-checkbox {
      margin-top: 30px;
    }

    .two-column {
      display: block;
    }

    .first {
      display: none;
    }

    .second {
      padding: 30px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .another-variants {
    display: block;
  }
  .form-group.d-flex {
    flex-direction: column;
  }
}
</style>
